import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '35, 31, 32',
		'primary-dark': '0, 0, 0',
		'accent': '237, 28, 36',
		'accent-plus': '255, 255, 255',
	},
});
